import Header from "@/shared/components/Header";
import Footer from "@/shared/components/Footer";
import Head from 'next/head';
import React, { useState, useEffect } from "react";
import styles from './styles.module.css';
import { PagegrowlerFunctions } from "@pagegrowler_nextjs";
import GenericSlider from "./components/Slider";
import SocialShare from "./components/SocialShare/social-share";
import BannerHorizontalChico from '@/widgets/Banner/Templates/BannerHorizontalChico';
import { ArrowsDouble } from '@/icons/index'
import PagegrowlerList from '@/widgets/Listado/Templates/PagegrowlerList'
import Templates from '@/widgets/index';

function Nota(props) {
	const CLIPPING_GALLERY = 'Galeria_XL_1565x0';
	const CLIPPING_THUMBNAIL = '3_2_230x153';

	const [isSliderActive, setIsSliderActive] = useState(false);
	const [startingIndex, setStartingIndex] = useState(0);

	//const [imgList, setImgList] = useState([]);
	const pagegrowlerFunctions = PagegrowlerFunctions();

	const {
		title,
		body,
		url,
		description,
		keywords
	} = { ...props.content };

	let menues = props.menu ? props.menu : [];
	const [open, setOpen] = useState(false);

	const setImg = (element, clipping) => {
		let elementImg = {
			images: [
				element.value &&
				{
					clippings: element.value.clippings || [],
					title: element.value.title || ''
				}
			]
		};
		return pagegrowlerFunctions.getFullImageObject(elementImg, clipping, process.env.NEXT_PUBLIC_IMAGE_DOMAIN);
	}
	let imgList = [];
	let images = body.map((el) => {
		let bigImgObj = setImg(el, CLIPPING_GALLERY);
		let smallImgObj = setImg(el, CLIPPING_THUMBNAIL);
		let imgObj = {
			bigImgUrl: bigImgObj.url,
			smallImgUrl: smallImgObj.url,
			author: el.author,
			epigraphe: el.epigraphe
		}
		return imgObj;
	});

	imgList = images;

	function openSlider(openIndex, e) {
		setStartingIndex(openIndex);
		setIsSliderActive(true);
	}

	function closeSlider() {
		setIsSliderActive(false);
	}

	function backToFn() {
		window.location.href = `/fotos-hd`;
	}
	let dataLDJson = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"name": "Diario El Litoral",
		"url": "https://www.ellitoral.com",
		"logo": {
			"@type": "ImageObject",
			"url": `${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/header_logoellitoral.svg`,
			"width": 439,
			"height": 60
		},
		"sameAs": ["https://www.facebook.com/ellitoralcom", "https://instagram.com/ellitoral", "https://twitter.com/ellitoral", "https://plus.google.com/+EllitoralArgentina"]
	};
	return (
		<>
			<Head>
				<title>{title}</title>
				<meta name="title" content={title} />
				{keywords && <meta property="keywords" content={keywords} />}
				{keywords && <meta property="news_keywords" content={keywords} />}
				<meta name="description" property="description" content={`${title} - El Litoral`} />
				<meta name="author" content="Ellitoral.com" />
				<meta name="og:url" property="og:url" content={'https://www.ellitoral.com' + url} />
				<meta name="og:type" property="og:type" content="article" />
				<meta name="og:title" property="og:title" content={title} />
				<meta name="og:description" property="og:description" content={description} />
				<meta name="robots" content="max-image-preview:large" />
				<meta name="og:image" property="og:image" content={ (imgList && imgList.length > 0) ? imgList[0].bigImgUrl : `${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/img-default-share.jpeg`} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:image" content={ (imgList && imgList.length > 0) ? imgList[0].bigImgUrl : `${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/img-default-share.jpeg`} />
				<link rel="canonical" href={process.env.NEXT_PUBLIC_DOMAIN_URL + url} />
				<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(dataLDJson) }} />
				<meta httpEquiv="refresh" content="3000" />
			</Head>
			<Header menues={menues} isOpenSidePanel={(open) => setOpen(open)} />
			
			<div itemScope itemType="https://schema.org/NewsArticle" className={`${styles["parent"]}`}>
				<div className={`${styles['container']}`}>
					<GenericSlider
						imgList={imgList}
						quantity={imgList.length}
						isSliderActive={isSliderActive}
						startingIndex={startingIndex}
						closeSlider={closeSlider}
						backToFn={backToFn}
						shareUrl={url}
					/>
					{!props.preview &&
						<div className={styles['banner-container']}>
							<BannerHorizontalChico bannerId={'top'} />
						</div>
					}

					<PagegrowlerList widget='CarouselDeGalerias' url='top-gallery' fallbackUrl='top-lilanews' isMobile={false}/>
					<PagegrowlerList widget='CarouselDeGalerias' url='top-gallery' fallbackUrl='top-lilanews' isMobile={true}/>

					<h1 itemProp="headline" className={`${styles['main-title']}`}>{title}</h1>
					<hr />
					<div className={`${styles['social-top']}`}>
						<SocialShare galleryUrl={url} align={"center"} bgColor={'216497'} />
					</div>


					<div className={styles['grid-container']}>
						<div className={styles['container-parent']}>
							{imgList.map((el, index) => {
								return (
									<div key={el + index}>
										<div className={`${styles['image-container']}`}>
											<img className={`${styles['image']}`} loading="lazy" alt={el.epigraphe} style={{ width: '600px', height: '600px' }} src={el.bigImgUrl} onClick={(e) => openSlider(index, e)} />
											<a className={`${styles['expand-container']}`} onClick={(e) => openSlider(index, e)}>
												<ArrowsDouble className={`${styles['rotate--45-dg']}`}/>
												<span>AMPLIAR</span>
											</a>
											<div className={`${styles['share-container']}`}>
												<SocialShare galleryUrl={url} />
											</div>
										</div>
										<p><strong>{index + 1} / {imgList.length}</strong> {el.epigraphe} {el.author && <strong>Foto: {el.author}</strong>}</p>
										<hr className={`${styles['marginBottom-L']}`} />
									</div>

								)
							})}
						</div>

						<div className={styles['container-list']}>
							<PagegrowlerList url='column-gallery' fallbackUrl="column-lilanews"/>
						</div>
					</div>
					<div className={styles['container-interesar']}>
						{!props.preview && <BannerHorizontalChico bannerId={'horizontal1'} />}
						<PagegrowlerList  widget='TePuedeInteresar' url='bottom-gallery' fallbackUrl='bottom-lilanews' />
					</div>
				</div>

			</div>
			<div style={{ clear: 'both' }}>
				<Footer />
			</div>
		</>
	);
}

export default Nota;
