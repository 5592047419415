import styles from './styles.module.css';

import { Facebook, Twitter, Whatssap } from '@/icons/index'

const SocialShare = (props) => {

    const {
        galleryUrl,
        align,
        bgColor
    } = {...props};

    var hexaReg = /[0-9A-Fa-f]{6}/g;

    const backgroundColor = bgColor && hexaReg.test(bgColor) ? `#${bgColor}` : 'none';
    const alignment = align == 'center' ? 'center' : align == 'right' ? 'right' : 'left';

    return (
        <div className={`${styles['container-icons']} ${styles[alignment]}`}>
            <a href={`https://www.facebook.com/sharer.php?u=https://www.ellitoral.com${galleryUrl}`}>
                <div className={`${styles['icons']} ${styles['color-close']}`} style={{backgroundColor: `${backgroundColor}`}}>
                    <Facebook
                        className={`${styles["icon"]} ${styles["facebook-icon"]}`}
                    />
                </div>
            </a>
            <a href={`http://twitter.com/share?url=https://www.ellitoral.com${galleryUrl}`}>
                <div className={`${styles['icons']} ${styles['color-close']}`} style={{backgroundColor: `${backgroundColor}`}}>
                    <Twitter
                        className={`${styles["icon"]} ${styles["twitter-icon"]}`}
                    />
                </div>
            </a>
            <a
                href={`https://api.whatsapp.com/send?text=GALERÍA%20DE%20IMÁGENES%20-%20El%20Litoral:%20https%3A%2F%2Fwww.ellitoral.com${galleryUrl}`}
                title={'Compartir en WhatsApp'}
            >
                <div className={`${styles['icons']} ${styles['color-close']}`} style={{backgroundColor: `${backgroundColor}`}}>
                    <Whatssap
                        className={`${styles["icon"]}`}
                    />
                </div>
            </a>
        </div>
    );
}

export default SocialShare;