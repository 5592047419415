import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import SocialShare from '../SocialShare/social-share';import { 
  Close,
  AgainRedo,
  List,
  ArrowRight,
  ArrowLeft
 } from '@/icons/index'

const GenericSlider = (props) => {

  const {
    imgList,
    quantity,
    isSliderActive,
    closeSlider,
    startingIndex,
    backToFn,
    shareUrl,
  } = { ...props };

  const [sliderIndex, setSliderIndex] = useState(0);
  const [showSlider, setShowSlider] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isHideFooter, setIsHideFooter] = useState(false);

  const minSwipeDistance = 75;

  function actionNext(event) {
    if (event) {
      event.stopPropagation();
    }

    if (sliderIndex < 0) {
      return;
    }

    if (sliderIndex === quantity - 1) {
      setSliderIndex(-1);
    } else {
      setSliderIndex(sliderIndex + 1);
    }
  }

  function actionPrev(event) {
    if (event) {
      event.stopPropagation();
    }

    if (sliderIndex < 0) {
      return;
    }

    setSliderIndex(sliderIndex - 1);
  }

  useEffect(() => {
    setSliderIndex(startingIndex);
    setShowSlider(isSliderActive);
    setIsHideFooter(false);

    if (isSliderActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isSliderActive])

  function closeOverlay() {
    closeSlider();
  }

  const upHandler = ({ key }) => {
    if (key === 'ArrowRight') {
      setTimeout(() => {
        actionNext();
      }, 0)
    } else if (key === 'ArrowLeft') {
      setTimeout(() => {
        actionPrev();
      }, 0)
    }
  };

  function watchAgain() {
    setSliderIndex(0);
  }

  const onClickTap = () => {
    setIsHideFooter(!isHideFooter);
  }

  const onTouchStart = (e) => {
    setTouchEnd(null) // avoid common touch event
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return;
    }

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe || isRightSwipe) {
      isLeftSwipe ? actionNext() : actionPrev();
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  });


  return (
    <div className={`${styles['overlay']} ${showSlider ? styles['show'] : styles['hide']}`}>
      <div className={`${styles['slider']} ${styles[isHideFooter ? 'hide-footer' : '']}`}>
        {sliderIndex > -1 ? (
          <img 
            className={`${styles['slider-img']}`} 
            src={imgList[sliderIndex]?.bigImgUrl} 
          />
        ) : (
          <div className={`${styles['related-container']}`}>
            <div className={`${styles['related-buttons']}`}>
              <div className={`${styles['related-button-container']}`} onClick={watchAgain}>
                <div className={`${styles['icon-border']}`}>
                  <AgainRedo className={`${styles['icon']}`}/>
                </div>
                <span>Ver de nuevo</span>
              </div>
              <div className={`${styles['related-button-container']}`} onClick={backToFn}>
                <div className={`${styles['icon-border']}`}>
                  <List className={`${styles['icon']}`}/>
                </div>
                <span>Ir a Galerías</span>
              </div>
            </div>
          </div>
        )}

        {sliderIndex > -1 && (
          <div 
            className={`${styles['controls']}`}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onClick={onClickTap}
          >
            <div className={`${styles['actions']}`}>
              <span>{sliderIndex + 1} de {quantity + 1}</span>
              <div>
                <a onClick={actionPrev} className={`${styles['arrows']}`}>
                  <ArrowLeft className={`${styles['icon']}`}/>
                </a>
                <a onClick={actionNext} className={`${styles['arrows']}`}>
                  <ArrowRight className={`${styles['icon']}`}/>
                </a>
              </div>
            </div>

            <div className={`${styles['options']}`}>
              <SocialShare galleryUrl={shareUrl} />
              <a onClick={closeOverlay} className={`${styles['close']}`}>
                CERRAR
              </a>

              <div onClick={closeOverlay} className={`${styles['button-close']}`}>
                <Close className={`${styles['icon']}`}/>
              </div>
            </div>
          </div>
        )}

        <div className={`${styles['description-container']}`}>
          {imgList[sliderIndex]?.epigraphe &&
            <h3>{imgList[sliderIndex]?.epigraphe} {imgList[sliderIndex]?.author && <strong>Foto: {imgList[sliderIndex]?.author}</strong>}</h3>
          }
        </div>
      </div>
    </div>
  )
}

export default GenericSlider;